<!-- PÁGINA DE INICIO. VARÍA SEGÚN MÓVIL O DESKTOP -->

<template>
  <Header />
  <Footer />
  <div class="page-container alignment-container q-mt-xl">
    <div class="col">
      <div v-if="settings.banner">
        <div v-if="settings.banner_url === ''">
          <img class="home-banner" :src="settings.banner" alt="" />
        </div>
        <a v-else :href="settings.banner_url">
          <img class="home-banner" :src="settings.banner" alt="" />
        </a>
      </div>
      <div class="container-home">
        <div>
          <HomeSliderProducts :points="points"/>
        </div>
        <div>
          <InfoPoints />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { mapActions } from 'vuex';


const Header = defineAsyncComponent(() =>
  import("../../shared/components/Header.vue")
);

const Footer = defineAsyncComponent(() =>
  import("../../shared/components/Footer.vue")
);

const InfoPoints = defineAsyncComponent(() =>
  import("../../shared/components/InfoPoints.vue")
);

const HelloUser = defineAsyncComponent(() =>
  import("../../shared/components/HelloUser.vue")
);

const HomeSliderProducts = defineAsyncComponent(() =>
  import("../components/HomeSliderProducts.vue")
);

const HomeSliderProductsMobile = defineAsyncComponent(() =>
  import("../components/HomeSliderProductsMobile.vue")
);

const SliderOptions = defineAsyncComponent(() =>
  import("../components/SliderOptions.vue")
);

export default {
  name: "Home",
  components: {
    Header,
    Footer,
    InfoPoints,
    HelloUser,
    HomeSliderProducts,
    HomeSliderProductsMobile,
    SliderOptions,
  },
  computed: {
    settings() {
      return this.$store.state.settings.settings;
    },
    points() {
      return this.$store.state.points.points;
    },
  },
  methods: {
    ...mapActions("points", ["getPoints"]),
  },
  mounted() {
    this.getPoints();
  },
};
</script>

<style lang="scss" scoped>
.container-home {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 60px 0px;
  justify-content: space-evenly;
  // background-color: red;
}

.home-banner {
  @media only screen and (min-width: 767px) {
    width: 60%;
  }

  @media only screen and (max-width: 767px) {
    width: 95%;
  }
}
</style>
